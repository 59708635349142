






































































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import {
  UserDto,
  UserWithCertificateStatusDto,
} from '@portals/shared/admin/UserDto';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import Vue from 'vue';

import AdminPopUp from '@/components/AdminPopUp.vue';
import DnbButton from '@/components/DnbButton.vue';
import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import TabsFilter from '@/components/TabsFilter.vue';
import { request } from '@/service/request';
import { fetchUsers } from '@/service/userService';

import { FilterValue } from '../../models/FilterValues';

type Data = {
  loading: boolean;
  users: UserWithCertificateStatusDto[];
  sortKey: 'email';
  reverse: boolean;
  displayDownloadUsersPopUp: boolean;
  filterValue: string;
};

interface FormatUser extends Omit<UserDto, 'firstName' | 'lastName'> {
  name: string;
}

export default Vue.extend({
  name: 'user-info',
  components: {
    Loader,
    DnbIcon,
    TableComponent,
    TableColumn,
    AdminPopUp,
    DnbButton,
    TabsFilter,
  },
  data(): Data {
    return {
      loading: false,
      users: [],
      sortKey: 'email',
      reverse: false,
      displayDownloadUsersPopUp: false,
      filterValue: 'all-users',
    };
  },

  computed: {
    formatUsers(): FormatUser[] {
      if (this.filterValue === 'certificate-users') {
        return this.users
          .filter((user) => user.hasCertificate)
          .map((user) => {
            const { firstName, lastName, ...formatedUser } = user;
            return {
              ...formatedUser,
              name: `${firstName} ${lastName}`,
            };
          });
      }
      return this.users.map((user) => {
        const { firstName, lastName, ...formatedUser } = user;
        return {
          ...formatedUser,
          name: `${firstName} ${lastName}`,
        };
      });
    },
    filterValues(): FilterValue[] {
      return [
        {
          name: 'All users',
          value: 'all-users',
        },
        {
          name: 'Users with PSD2 certificates',
          value: 'certificate-users',
        },
      ];
    },
  },

  mounted() {
    this.loadUserInfo();
  },

  methods: {
    onRowClick(row: { data: UserDto }) {
      this.$router.push(`users/${row.data.id}`);
    },
    async loadUserInfo() {
      this.loading = true;
      this.users = await fetchUsers();
      this.loading = false;
    },
    downloadUsersPopUp() {
      this.displayDownloadUsersPopUp = !this.displayDownloadUsersPopUp;
    },

    setUserFilter(input: string) {
      this.filterValue = input;
    },

    downloadUserInfo() {
      this.loading = true;
      this.displayDownloadUsersPopUp = false;
      request({ url: 'users/download' })
        .then((response: AxiosResponse) => {
          const BOM = '\uFEFF';
          const resp = response.data;
          const blob = new Blob([BOM + resp], {
            type: response.headers['content-type:data:text/csv;charset=utf-8;'],
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Registered users.csv';
          link.click();
          this.loading = false;
          return;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Download User Info error', error);
          this.loading = false;
        });
    },
    dateFormatter(date: Date) {
      if (!date) return 'N/A';
      return format(date, 'DD.MM.YYYY HH:mm');
    },
  },
});
